@import '../../resources/styles/designSystem.scss';
.primary-btn {
  height: 40px;
  width: 144px;
  border-radius: 40px !important;
  background: linear-gradient(270deg, $primaryGradientColor 0%, $secondaryGradientColor 100%) !important;
  border: none !important;
  color: white !important;
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
  &:hover {
    box-shadow: 0px 0px 0px 4px RGBA(255, 108, 108, 0.25) !important;
    background: linear-gradient(270deg, $primaryGradientColor 0%, $secondaryGradientColor 100%) !important;
    border: none !important;
    color: white !important;
    transition: box-shadow 200ms ease-out !important;
  }
}

.primary-btn-disabled {
  pointer-events: none;
  opacity: 0.65;
}

.secondary-btn {
  height: 40px;
  width: 144px;
  border-radius: 40px !important;
  margin: 0;
  background: white !important;
  border: 2px solid $primaryColor !important;
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
  color: $primaryColor !important;
  &:hover {
    box-shadow: 0px 0px 0px 4px RGBA(255, 108, 108, 0.25) !important;
    background: white !important;
    border: 2px solid $primaryColor !important;
    transition: box-shadow 200ms ease-out !important;
    color: $primaryColor !important;
  }
}

.tertiary-btn {
  height: 40px;
  width: 144px;
  border-radius: 40px !important;
  margin: 0;
  background: white !important;
  border: 2px solid white !important;
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
}

.secondary-btn-disabled {
  pointer-events: none;
  opacity: 0.65;
}

.selected-btn {
  height: 40px;
  width: 144px;
  border-radius: 40px !important;
  margin: 0;
  background: white !important;
  border: 2px solid darkgray !important;
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
  color: darkgray !important;
}