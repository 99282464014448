@import '../../../resources/styles/designSystem.scss';

.bottombarOption {
  display: flex;
  justify-content: center;
  align-items: center;
  .bottombar-link {
    display: flex;
    font-size: 28px;
    font-weight: 500;
    line-height: 20px;
    color: black;
  }
}

.activeBottombarOption {
  border: 1.5px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  box-shadow: 1px 1px 4px RGB(0 0 0 / 12%);
  border-radius: 50%;
  transition: box-shadow 100ms ease-out;
  cursor: pointer;
  width: 56px;
  height: 56px;
}