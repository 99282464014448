@import '../../resources/styles/designSystem.scss';
.bottom-bar {
  display: flex;
  position: fixed;
  width: 100%;
  justify-content: space-between;
  bottom: 0;
  height: 80px;
  left: 0;
  z-index: 2000;
  background: white;
  box-shadow: 0px -1px 8px RGBA(0, 0, 0, 0.12);
  border-radius: 24px 24px 0px 0px;
  padding: 10px;
  .new-post-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .new-post-link {
      background: linear-gradient(270deg, $primaryGradientColor 0%, $secondaryGradientColor 100%);
      border-radius: 60%;
      color: white;
      width: 56px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 26px;
    }
  }
}

@media screen and (min-width: 768px) {
  .bottom-bar {
    display: none;
  }
}