@import "../../resources/styles/designSystem.scss";
.verify-email-container {
  padding: 8px;
  font-size: 16px;
  background: linear-gradient(270deg, $primaryGradientColor 0%, $secondaryGradientColor 100%);
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  position: sticky;
  top: 60px;
  margin: 0 auto;
  width: 95%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  z-index: 200;
  .content {
    color: white;
    margin-right: 10px;
    .action {
      .tertiary-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 16px !important;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .verify-email-container {
    width: 90%;
  }
}
