.sider-container {
  display: none;
}

@media only screen and (min-width: 768px) {
  .sider-container {
    display: block;
    min-height: 100vh;
    height: 100vh;
    min-width: 20%;
    width: 20%;
  }
}