body, html {
  margin: unset !important;
  font-family: 'Poppins', sans-serif !important;
}

.defaultBody {
  background: linear-gradient(0deg, #F8F8F8, #F8F8F8), linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #F8F8F8;
  padding-left: 2.5%;
  padding-right: 2.5%;
}

form {
  box-shadow: unset !important;
}