@import '../../../resources/styles/designSystem.scss';

.sidebarOption {
  margin-bottom: 25px;
  .sidebar-link {
    display: flex;
    font-size: 19px;
    font-weight: 500;
    line-height: 20px;
    color: black;
    padding: 14px 25px;
    .link-content {
      margin-left: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.sidebarOptionHover {
  &:hover {
    box-sizing: border-box;
    box-shadow: 0px 0px 10px RGB(0 0 0 / 12%);
    border-radius: 80px;
    transition: box-shadow 100ms ease-out;
    cursor: pointer;
  }
}

.activeSidebarOption {
  border: 1.5px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  box-shadow: 1px 1px 4px RGB(0 0 0 / 12%);
  border-radius: 80px;
  transition: box-shadow 100ms ease-out;
  cursor: pointer;
}