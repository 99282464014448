@import '../../../resources/styles/designSystem.scss';

#normal_login {
  width: 350px;
  .login-form-button {
    width: 100%;
    background-color: $primaryColor;
    border-color: $primaryColor;
    border-radius: 40px;
  }
  .ant-form-item {
    margin-bottom: 10px;
    a {
      color: $primaryColor;
    }
  }
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color: $primaryColor;
    box-shadow: 0 0 0 2px #ff75ab4f;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: $primaryColor;
  }
}