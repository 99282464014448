.user-list-container {
  overflow-y: scroll;
  .user-container {
    display: flex;
    padding: 16px;
    .user-profile-avatar {
      margin-right: 16px;
    }
    .name-container {
      flex: 2;
      .username {
        font-size: 14px;
        font-weight: 600;
        color: black;
      }
      .extra-info-container {
        display: flex;
        justify-content: space-between;
        position: relative;
        .name {
          font-size: 14px;
          font-weight: 500;
          color: #716e6e;
        }
        .subscriber {
          position: absolute;
          right: 0;
          margin-top: -15px;
          img {
            width: 28px;
            vertical-align: 0px;
          }
        }
      }
    }
    .remove-container {
      .remove-icon {
        &:hover {
          cursor: pointer !important;
          background-color: darkgray !important;
          border-radius: 50% !important;
        }
      }
    }
    &:hover {
      background-color: #f8f8f8;
      cursor: pointer;
    }
  }
}