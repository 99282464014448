.search-bar-container {
  display: flex;
  flex-direction: column;
  width: 18em;
  height: 1.8em;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0px 1px 8px RGBA(0, 0, 0, 0.12);
  overflow: hidden;
  margin: 10px 20px 12px 0px;
  .search-input-container {
    width: 100%;
    min-height: 2em;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding: 2px 15px;
    .search-input {
      width: 100%;
      height: 100%;
      outline: none;
      border: none;
      font-size: 16px;
      color: black;
      border-radius: 6px;
      font-weight: 500;
      background-color: transparent;
      margin-left: 10px;
      &:focus {
        outline: none;
        &::placeholder {
          opacity: 0;
        }
      }
      &::placeholder {
        color: #ebebeb;
        transition: all 250ms ease-in-out;
      }
    }
  }
  .line-separator {
    display: flex;
    min-width: 100%;
    min-height: 2px;
    background-color: #ebebeb;
  }
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30%;
  }
  .no-results-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30%;
    font-size: 16px;
    font-weight: 500;
  }
}

@media (max-width: 767px) {
  .search-bar-container {
    margin: 0 auto;
    margin-top: 10px;
  }
}