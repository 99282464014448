.user-search-history-container {
  position: relative;
  .search-header {
    display: flex;
    justify-content: space-between;
    padding: 30px 15px;
    .title {
      font-size: 16px;
    }
    .action {
      cursor: pointer;
      font-weight: 600;
      font-size: 16px;
      color: RGBA(20, 128, 255, 1);
    }
  }
  .no-search-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30%;
    font-size: 16px;
    font-weight: 500;
  }
  .overlay-container {
    display: flex;
    justify-content: center;
    height: 18em;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: RGBA(0,0,0,0.5);
    z-index: 2;
  }
  .user-list-container {
    max-height: 14em;
    overflow: scroll;
    .user-container {
      display: flex;
      padding: 16px;
      width: 100%;
      .user-profile-avatar {
        margin-right: 16px;
      }
      .name-container {
        flex: 2;
        .username {
          font-size: 14px;
          font-weight: 600;
          color: black;
        }
        .name {
          font-size: 14px;
          font-weight: 500;
          color: #716e6e;
        }
      }
      .remove-container {
        .remove-icon {
          &:hover {
            cursor: pointer !important;
            background-color: rgb(197, 195, 195) !important;
            border-radius: 50% !important;
          }
        }
      }
      &:hover {
        background-color: #f8f8f8;
        cursor: pointer;
      }
    }
  }
}