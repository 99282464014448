@import '../../../resources/styles/designSystem.scss';
.authentication-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 33px;
    color: rgb(26, 25, 43);
    margin: 63px 0px !important;
    img {
      width: 163.39px;
      height: 182px !important;
      margin-bottom: 30.43px !important;
    }
  }
  .social-login-container {
    width: 100%;
    text-align: center;
    .social-btn-container {
      margin-bottom: 20px;
      button {
        padding: 8px 40px 8px 32px;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.8);
        box-sizing: border-box;
        border-radius: 40px;
        width: 55%;
        cursor: pointer;
      }
      .google-btn {
        svg {
          vertical-align: -5px;
          font-size: 1.4em;
          margin-right: 8px;
        }
      }
    }
  }
  .separation-container {
    display: flex;
    width: 48%;
    margin-bottom: 20px;
    .horizontal-bar {
      border-bottom: 1px solid #ebebeb;
      height: 1px;
      width: 50%;
      margin-top: 15px;
    }
    span {
      margin-left: 5px;
      margin-right: 5px;
      font-size: 18px;
    }
  }
  .subtext {
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    line-height: 33px;
    max-width: 440px;
    margin: 8px 0px 24px;
    color: #4a495e !important;
  }
  .redirection {
    font-size: 18px;
    font-family: 'Poppins';
    font-weight: 500;
    color: #888787;
    margin-top: 20px;
    span {
      text-decoration-color: $primaryColor !important;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .terms-policies-container {
    font-size: 18px;
    margin-top: 10px;
    color: #888787;
    font-weight: 500;
    text-align: center;
    .link {
      color: $primaryColor;
      text-decoration: underline;
    }
  }
}