@import '../../resources/styles/designSystem.scss';

.footer-authentication-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  background: linear-gradient(270deg, $primaryGradientColor 0%, $secondaryGradientColor 100%);
  z-index: 2000;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 20px;
  font-weight: 600;
  color: white;
  justify-content: center;
  align-items: center;
  .field {
    margin-right: 10px;
    .primary-btn {
      border: 2px solid white !important;
    }
  }
  .auth-btns-group {
    display: flex;
    margin-top: 10px;
    .field {
      margin-right: 10px;
      .primary-btn {
        border: 2px solid white !important;
      }
    }
  }
  .content {
    text-align: center;
    margin-right: 30px;
  }
}

@media only screen and (min-width: 768px) {
  .footer-authentication-container {
    flex-direction: row;
    .auth-btns-group {
      display: flex;
      .field {
        margin-right: 10px;
        .primary-btn {
          border: 2px solid white !important;
        }
      }
    }
    .content {
      margin-right: 30px;
    }
  }
}