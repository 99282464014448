.global-header-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 101%;
  height: 60px;
  position: sticky;
  top: 0px;
  z-index: 100;
  background-color: white;
  box-shadow: 0 1px 8px RGBA(0 0 0/12%);
  font-weight: 600;
  font-size: 20px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  .sidebar__logo {
    font-size: 30px;
    margin-bottom: 24px;
    width: 84px;
    margin-left: 24px;
    max-width: 70px;
    cursor: pointer;
    margin-top: 12px;
    flex: 1;
    height:40px;
  }
  .icon {
    margin-left: 30px;
    cursor: pointer;
  }
  .text {
    flex: 1;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    display: flex;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .global-header-bar {
    font-size: 16px;
    .text {
      flex: 1;
      text-align: center;
    }
    .mobileSearch {
      color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      font-size: 18px;
    }
  }
}
