@import '../../../resources/styles/designSystem.scss';
.authentication-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 33px;
    color: rgb(26, 25, 43);
    margin: 24px 0px;
    img {
      height: 40px;
      margin-bottom: 35px;
    }
  }
  .subtext {
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    line-height: 33px;
    max-width: 440px;
    margin: 8px 0px 24px;
    color: #4a495e !important;
  }
  .redirection {
    font-size: 18px;
    font-family: 'Poppins';
    font-weight: 500;
    color: #888787;
    margin-top: 20px;
    span {
      text-decoration-color: $primaryColor !important;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}