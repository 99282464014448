@import "../../resources/styles/designSystem.scss";
.language-switcher-container {
  z-index: 2000;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  display: flex;
  margin-right: 16px;
  .ant-select {
    .ant-select-selection-placeholder {
      font-size: 20px;
      color: $primaryColor;
    }
    .ant-select-arrow {
      color: $primaryColor;
    }
    .ant-select-selection-item {
      color: $primaryColor;
      font-size: 16px;
    }
  }
}

.language-dropdown {
  min-width: 150px !important;
  left: unset !important;
  right: 20px !important;
}

.white-color {
  .ant-select {
    .ant-select-selection-placeholder {
      color: white;
    }
    .ant-select-arrow {
      color: white;
    }
    .ant-select-selection-item {
      color: white;
    }
  }
  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: white;
  }
}

.primary-color {
  .ant-select {
    .ant-select-selection-placeholder {
      color: $primaryColor;
    }
    .ant-select-arrow {
      color: $primaryColor;
    }
    .ant-select-selection-item {
      color: $primaryColor;
    }
  }
  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: $primaryColor;
  }
}
