@import '../../resources/styles/designSystem.scss';

.sidebar {
  display: none;
}
@media only screen and (min-width: 768px) {
  .sidebar {
    display: block;
    padding-left: 20px;
    padding-right: 20px;
    position: fixed;
    margin-left: 0px;
    width: 19%;
    max-width: 19%;
    border-radius: 24px;
    background: white;
    padding-top: 20px;
    box-shadow: 0px 1px 8px RGBA(0 0 0/12%);
    min-height: 100vh;
  }
}