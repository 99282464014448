.main-loader {
  display: flex;
  flex: 0.8;
  justify-content: center;
  height: 100vh;
  align-items: center;
}

@media (max-width: 767px) {
  .main-loader {
    flex: 1;
    height: 80vh;
  }
}
