@import '../../resources/styles/designSystem.scss';

.gradient-text {
  background-color: $primaryColor;
  background: linear-gradient(270deg, $primaryGradientColor 0%, $secondaryGradientColor 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  background-size: 100%;
}